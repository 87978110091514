import {RouteConfigSingleView} from 'vue-router/types/router';

export const ActionRoutingModule: RouteConfigSingleView[] = [
    {
        path: 'actie-beheer',
        name: 'actions-overview',
        component: () => import('./pages/actions-overview/actions-overview.component').then(
            c => c.ActionsOverviewComponent
        ),
        meta: {
            requiresAuth: true,
            title: 'Actie templates',
        },
        children: [
            {
                path: 'templates',
                name: 'tax-office-template-manager',
                component: () => import('./pages/template-manager/template-manager.component').then(
                    c => c.TemplateManagerComponent
                ),
            },
            {
                path: ':actionType',
                name: 'edit-action',
                component: () => import('./pages/edit-action/edit-action.component').then(
                    c => c.EditActionComponent
                ),
            },
        ],
    },
]
