import {RouteConfigSingleView} from "vue-router/types/router";
import {TaxOfficeRouterViewComponent} from "./pages/router-view/tax-office-router-view.component";
import {TaxOfficesOverviewComponent} from "./pages/overview/tax-offices-overview.component";
import {TaxOfficeGeneralDataComponent} from "./pages/general-data/tax-office-general-data.component";
import {TaxOfficeDetailsComponent} from "./pages/details/tax-office-details.component";
import {AccessTokensComponent} from "./pages/access-tokens/access-tokens.component";
import {TaxOfficeApisComponent} from "./pages/apis/tax-office-apis.component";
import {ActionRoutingModule} from "./pages/action-templates/action-routing.module";
import {TaxOfficeUsersOverviewComponent} from "./pages/users/tax-office-users-overview.component";

export const TaxOfficeRoutingModule: RouteConfigSingleView[] = [
    {
        path: 'belastingkantoren',
        name: 'tax-offices-overview',
        component: TaxOfficeRouterViewComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Belastingkantoren',
                to: 'tax-offices-overview'
            }
        },
        children: [
            {
                path: 'overzicht',
                name: 'tax-offices-overview',
                component: TaxOfficesOverviewComponent,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: ':taxOfficeId',
                name: 'tax-office-general-data',
                component: TaxOfficeDetailsComponent,
                props: true,
                meta: {
                    requiresAuth: true,
                    breadcrumb: {
                        title: 'Details',
                        type: 'resolver',
                    },
                },
                children: [
                    {
                        path: 'algemene-gegevens',
                        name: 'tax-office-general-data',
                        component: TaxOfficeGeneralDataComponent,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            breadcrumb: {
                                title: 'Algemene gegevens',
                            },
                        },
                    },
                    {
                        path: 'gebruikers',
                        name: 'tax-office-users-overview',
                        props: true,
                        component: TaxOfficeUsersOverviewComponent,
                        meta: {
                            requiresAuth: true,
                            title: 'Beheerders',
                        },
                    }, {
                        path: 'access-tokens',
                        name: 'tax-office-access-tokens',
                        component: AccessTokensComponent,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            breadcrumb: {
                                title: 'Access tokens',
                            },
                        },
                    },
                    {
                        path: 'api-tokens',
                        name: 'tax-office-api-tokens',
                        component: TaxOfficeApisComponent,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            breadcrumb: {
                                title: 'API tokens',
                            },
                        },
                    },
                    ...ActionRoutingModule
                ],
            },
        ],
    },
];
