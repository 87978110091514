import Vue from 'vue';
import Component from 'vue-class-component';
import html from './audit-logs-overview.component.html';
import { Inject } from 'vue-property-decorator';
import { AuditEntity, AuditLog, AuditMarkup, AuditProperty } from '@ncnp-mono/generics';
import { AuditService } from '@ncnp-mono/utils';

@Component({
    template: html,
})
export class AuditLogsOverviewComponent extends Vue {

    @Inject()
    auditService!: AuditService;

    public limit = 50;
    public page = 1;

    public entityClass = this.$route.params.entity;
    public response: AuditEntity | null = null;
    public logs: AuditLog[] = [];

    public loading = false;

    get totalPages(): number {
        return this.response ? Math.ceil(this.response.logCount / this.limit) : 0;
    }

    async beforeMount(): Promise<void> {
        await this.getPaginatedLogs();
    }

    public async getPaginatedLogs(): Promise<void> {
        this.loading = true;

        try {
            this.response = await this.auditService.getPaginatedLogs(
                this.entityClass,
                this.page,
                this.limit
            );

            this.logs = this.response.logs;
        } catch (e) {
            this.$snackbar.danger('Het ophalen van de audit audit-logs is mislukt.');
        }

        this.loading = false;
    }

    private getMarkup(auditType: string): AuditMarkup {
        switch (auditType) {
            case 'insert':
                return {
                    color: 'green',
                    action: 'aangemaakt',
                    icon: 'mdi-table-plus',
                };
            case 'remove':
                return {
                    color: 'red',
                    action: 'verwijderd',
                    icon: 'mdi-table-remove',
                };
            case 'update':
                return {
                    color: 'blue',
                    action: 'gewijzigd',
                    icon: 'mdi-table-edit',
                };
            case 'associate':
                return {
                    color: 'purple',
                    action: 'gekoppeld',
                    icon: 'mdi-link-variant-plus',
                };
            case 'dissociate':
                return {
                    color: 'orange',
                    action: 'ontkoppeld',
                    icon: 'mdi-link-variant-minus',
                };
            default:
                return {
                    color: '',
                    action: 'onbekend',
                    icon: '',
                };
        }
    }

    public getColor(auditType: string): string {
        return this.getMarkup(auditType).color;
    }

    public getAction(auditType: string): string {
        return this.getMarkup(auditType).action;
    }

    public getIcon(auditType: string): string {
        return this.getMarkup(auditType).icon;
    }

    public getPropertyDiffs(value: AuditProperty): { new: string; old: string; } {
        return {
            'new': (typeof value.new === 'object' ? value.new?.label : value.new) ?? 'null',
            'old': (typeof value.old === 'object' ? value.old?.label : value.old) ?? 'null',
        };
    }

    public getEntityName(entityClass: string) {
        return entityClass.split('\\').pop();
    }

    public isExpandable(logType: string): boolean {
        return logType === 'update' || logType === 'insert';
    }
}
