import {
    email,
    hasNoScriptTags,
    iban,
    max,
    min,
    numeric,
    required,
    telephoneNumber
} from "@bakerware/vue-validation-rules";

export const TaxOfficeRules = {
    name: [required, (v: string) => max(v, 128), hasNoScriptTags],
    shortName: [required, (v: string) => max(v, 25), hasNoScriptTags],
    code: [required, (v: string) => max(v, 10), hasNoScriptTags],
    kvk: [
        required,
        numeric,
        (v: string) => min(v, 8),
        (v: string) => max(v, 8)
    ],
    iban: [
        required,
        iban,
        (v: string) => min(v, 18),
        (v: string) => max(v, 18)
    ],
    email: [required, email],
    phoneNumber: [required, telephoneNumber],
    backendType: [
        required,
        (v: string) => max(v, 15)],
    taxType: [
        required,
        (v: string) => max(v, 15)],
    offlineMessage: [hasNoScriptTags]
};
