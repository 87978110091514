import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { required } from '@bakerware/vue-validation-rules';
import { IsDialogMixin } from '@bakerware/vue-dialogs';
import { UpdateApiTokenDialog } from './dialogs/update-api-token/update-api-token.dialog';
import { CreateApiTokenDialog } from './dialogs/create-api-token/create-api-token.dialog';
import html from './tax-office-apis.component.html';
import { TaxOfficeApiOverviewHeaders } from "../../config/tax-office-api-overview-headers";
import { TaxOfficeApiService } from "@ncnp-mono/utils";
import { TaxOfficeApi, TaxOfficeApiRequest, TaxOfficeApiSettings } from "@ncnp-mono/generics";

@Component({
    template: html,
})
export class TaxOfficeApisComponent extends IsDialogMixin {

    @Inject()
    taxOfficeApiService!: TaxOfficeApiService;

    public taxOfficeApis: TaxOfficeApi[] = [];
    public headers = TaxOfficeApiOverviewHeaders;
    public request: TaxOfficeApiRequest = {};
    public settings: TaxOfficeApiSettings[] = [];
    public loading = true;
    public actionTitle = '';

    public rules = {
        type: [ required, ],
        url: [ required, ],
        setting: [ required, ],
    };

    public async beforeMount(): Promise<void> {
        this.taxOfficeApis = await this.taxOfficeApiService.getTaxOfficeApis(
            parseInt(this.$route.params.taxOfficeId)
        );
        this.loading = false;
    }

    public openUpdateApiTokenDialog(taxOfficeApi: TaxOfficeApi): void {
        const updateApiTokenDialog = this.$vueDialogs.open(UpdateApiTokenDialog, {
            propsData: {
                taxOfficeApi,
            },
            dialogSettings: {
                maxWidth: '1024px',
            },
        });

        updateApiTokenDialog.result.observe((updatedTaxOfficeApi: TaxOfficeApi) => {
            const taxOfficeApis = [ ...this.taxOfficeApis, ];
            const taxOfficeApiIndex =
                this.taxOfficeApis.findIndex(
                    (_taxOfficeApi) =>
                        _taxOfficeApi.id === taxOfficeApi.id
                );

            taxOfficeApis[taxOfficeApiIndex] = updatedTaxOfficeApi;

            this.taxOfficeApis = taxOfficeApis;
        });
    }

    public openCreateApiTokenDialog(taxOfficeApi: TaxOfficeApi): void {
        const createApiTokenDialog = this.$vueDialogs.open(CreateApiTokenDialog, {
            propsData: {
                taxOfficeApi,
            },
            dialogSettings: {
                maxWidth: '1024px',
            },
        });

        createApiTokenDialog.result.observe((createdTaxOfficeApi: TaxOfficeApi) => {
            this.taxOfficeApis = [
                createdTaxOfficeApi,
                ...this.taxOfficeApis,
            ];
        });
    }
}
