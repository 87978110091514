import Vue from "vue";
import Component from "vue-class-component";
import html from "./tax-office-details.component.html";
import {BreadcrumbService, getImage, TaxOfficeService} from "@ncnp-mono/utils";
import type {TaxOffice} from "@ncnp-mono/generics";
import {Inject, Prop} from "vue-property-decorator";

@Component({
    template: html,
    methods: {
        getImage
    }
})
export class TaxOfficeDetailsComponent extends Vue {

    @Inject()
    private breadcrumbService!: BreadcrumbService;

    @Inject()
    private taxOfficeService!: TaxOfficeService;

    @Prop()
    private _taxOffice?: TaxOffice;
    public taxOffice?: TaxOffice | null = null;

    public item = 0;
    public items: object = [];

    private async beforeMount(): Promise<void> {
        this.createMenuItems();
        await this.getTaxOffice();
    }

    private async getTaxOffice(): Promise<void> {
        this.taxOffice = this._taxOffice ?? await this.taxOfficeService.getTaxOffice(parseInt(this.$route.params.taxOfficeId));
        this.breadcrumbService.updateBreadcrumbResolver(this.taxOffice.shortName);
    }

    private createMenuItems(): void {
        this.items = [
            {
                title: 'Algemene gegevens',
                icon: 'mdi-city',
                to: {
                    name: 'tax-office-general-data',
                    params: {taxOfficeId: this.$route.params.taxOfficeId,},
                },
            },
            {
                title: 'Beheerders',
                icon: 'mdi-account-outline',
                to: {
                    name: 'tax-office-users-overview',
                    params: {taxOfficeId: this.$route.params.taxOfficeId,},
                },
            },
            {
                title: 'API tokens',
                icon: 'mdi-api',
                to: {
                    name: 'tax-office-api-tokens',
                    params: {taxOfficeId: this.$route.params.taxOfficeId,},
                },
            },
            {
                title: 'Access tokens',
                icon: 'mdi-arrow-collapse-right',
                to: {
                    name: 'tax-office-access-tokens',
                    params: {taxOfficeId: this.$route.params.taxOfficeId,},
                },
            },
            {
                title: 'Actie templates',
                icon: 'mdi-gesture-tap-hold',
                to: {
                    name: 'tax-office-template-manager',
                    params: {taxOfficeId: this.$route.params.taxOfficeId,},
                },
            },
        ];
    }
}
