import Component from 'vue-class-component';
import {Inject, Prop, Ref} from 'vue-property-decorator';
import html from './tax-office-contact-edit.dialog.html';
import { IsDialogMixin } from "@bakerware/vue-dialogs";
import { ContactService, TaxOfficeContactService } from "@ncnp-mono/utils";
import type { ContactRequest, Contact } from "@ncnp-mono/generics";
import {Observable} from "@bakerware/vue-utils";
import type {VForm} from "@bakerware/vue-utils";
import { email, required } from "@bakerware/vue-validation-rules";
import { ConfirmDialog } from "@ncnp-mono/components";

@Component({
    template: html
})
export class TaxOfficeContactEditDialog extends IsDialogMixin {

    @Inject()
    taxOfficeContactService!: TaxOfficeContactService;

    @Inject()
    contactService!: ContactService;

    @Ref('form')
    form!: VForm;

    @Prop()
    contact!: Contact;

    public request: ContactRequest = {};

    public result = new Observable<Contact | null>();

    public rules = {
        email: [required, email,],
        name: [required,],
        website: [required,],
    };

    public mounted(): void {
        this.request = {
            ...this.contact,
        };
    }

    public remove(): void {

        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om deze contactpersoon te verwijderen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(this.removeContact);
    }

    public async submit(): Promise<void> {
        if (!this.form.validate() || !this.contact.id) {
            return;
        }

        try {
            const contact = await this.contactService.updateContact(
                this.contact.id,
                this.request
            );

            this.result.emit(contact);

            this.$snackbar.success('Aanmaken contactpersoon gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Aanmaken contactpersoon mislukt');
        }
    }

    private async removeContact(): Promise<void> {
        try {
            await this.contactService.deleteContact(this.contact.id);

            this.result.emit(null);

            this.$snackbar.success('Verwijderen contactpersoon gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Verwijderen contactpersoon mislukt, probeer opnieuw');
        }
    }
}
