import Vue from 'vue';
import Component from 'vue-class-component';
import html from './audit-router-view.component.html';

@Component({
    template: html
})
export class AuditRouterViewComponent extends Vue {

}
