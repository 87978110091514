import Vue from "vue";
import Component from "vue-class-component";
import html from "./tax-offices-overview.component.html";
import { Inject } from "vue-property-decorator";
import { TaxOffice } from "@ncnp-mono/generics";
import { getImage, TaxOfficeService } from "@ncnp-mono/utils";
import { TaxOfficeCreateDialog } from "./dialogs/tax-office-create.dialog";

@Component({
    template: html,
    methods: {
        getImage
    }
})
export class TaxOfficesOverviewComponent extends Vue {

    @Inject()
    private taxOfficeService!: TaxOfficeService;

    public taxOffices: TaxOffice[] = [];

    public openTaxOfficeCreateDialog(): void {
        this.$vueDialogs.open(
            TaxOfficeCreateDialog,
            {
                dialogSettings: {
                    maxWidth: "800px"
                }
            }
        ).result.observe(this.createTaxOffice);
    }

    private mounted(): void {
        this.getTaxOffices();
    }

    private getTaxOffices(): void {
        this.taxOfficeService.getTaxOffices().then(result => this.taxOffices = result);
    }

    private createTaxOffice(taxOffice: TaxOffice): void {
        this.taxOffices.push(taxOffice);
    }
}
