import Component from 'vue-class-component';
import html from "./tax-office-user-edit.dialog.html";
import {Inject, Prop, Ref} from 'vue-property-decorator';
import {IsDialogMixin, Observable} from '@bakerware/vue-dialogs';
import {TaxOfficeUserService, UserService} from "@ncnp-mono/utils";
import type {VForm} from "@bakerware/vue-utils";
import {TaxOfficeUserRequest, type User} from "@ncnp-mono/generics";
import {TaxOfficeUserRules} from "../../../config/tax-office-user-rules";

@Component({
    template: html,
})
export class TaxOfficeUserEditDialog extends IsDialogMixin {

    @Inject()
    taxOfficeUserService!: TaxOfficeUserService;

    @Inject()
    userService!: UserService;

    @Ref('form')
    public form!: VForm;

    @Prop()
    public taxOfficeUser!: User;

    public result = new Observable<User>();
    public request: TaxOfficeUserRequest = {};
    public rules = TaxOfficeUserRules;

    public created(): void {
        this.request = {
            userGroupId: 0,
            ...this.taxOfficeUser,
        };
    }

    async submit() {
        if (!this.form.validate()) {
            return;
        }

        try {
            const taxOfficeUser =
                await this.taxOfficeUserService.updateTaxOfficeUser(
                    this.taxOfficeUser.id,
                    this.request
                );

            this.result.emit(taxOfficeUser);
            this.$snackbar.success('Wijzigen gebruiker gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Wijzigen gebruiker mislukt');
        }
    }
}
