import Component from 'vue-class-component';
import { IsDialogMixin, Observable } from '@bakerware/vue-dialogs';
import { Inject, Prop } from 'vue-property-decorator';
import html from './revoke-access-token.dialog.html';
import type { TaxOfficeAccessTokenInterface, TaxOfficeAccessTokensService } from "@ncnp-mono/utils";

@Component({
    template: html,
})
export class RevokeAccessTokenDialog extends IsDialogMixin {
    @Prop()
    public accessToken!: TaxOfficeAccessTokenInterface;

    @Inject()
    taxOfficeAccessTokensService!: TaxOfficeAccessTokensService;

    public confirmed = new Observable();

    public async confirm(): Promise<void> {
        try {
            await this.taxOfficeAccessTokensService.revokeAccessToken(
                +this.$route.params.taxOfficeId,
                this.accessToken.id
            );

            this.confirmed.emit(null);
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.warning('Uitschakelen van Access token is mislukt');
        }
    }
}
    
