import Component from 'vue-class-component';
import {Inject, Prop, Ref} from 'vue-property-decorator';
import html from './tax-office-contact-create.dialog.html';
import { IsDialogMixin } from "@bakerware/vue-dialogs";
import { TaxOfficeContactService } from "@ncnp-mono/utils";
import { Contact, ContactRequest } from "@ncnp-mono/generics";
import {Observable} from "@bakerware/vue-utils";
import type {VForm} from "@bakerware/vue-utils";
import { email, required } from "@bakerware/vue-validation-rules";

@Component({
    template: html
})
export class TaxOfficeContactCreateDialog extends IsDialogMixin {

    @Inject()
    taxOfficeContactService!: TaxOfficeContactService;

    @Ref('form')
    form!: VForm;

    @Prop()
    taxOfficeId!: number

    public request: ContactRequest = {};
    public result = new Observable<Contact>();
    public rules = {
        email: [required, email,],
        name: [required,],
        website: [required,],
    };

    async submit(): Promise<void> {
        if (!this.form.validate() || !this.taxOfficeId) {
            return;
        }

        try {
            const contact = await this.taxOfficeContactService.createTaxOfficeContact(
                this.taxOfficeId.toString(),
                this.request
            );

            this.result.emit(contact);

            this.$snackbar.success('Aanmaken contactpersoon gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Aanmaken contactpersoon mislukt');
        }
    }
}
