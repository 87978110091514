import Vue from "vue";
import Component from 'vue-class-component';
import html from './audit-entities-overview.component.html';
import { Inject } from 'vue-property-decorator';
import { AuditService } from '@ncnp-mono/utils';
import { AuditEntity } from '@ncnp-mono/generics';
import { DataTableHeader } from 'vuetify';
import { AuditEntitiesOverviewHeaders } from '../../config';

@Component({
    template: html,
})
export class AuditEntitiesOverviewComponent extends Vue {

    @Inject()
    auditService!: AuditService;

    public entities: AuditEntity[] = [];

    public headers: DataTableHeader[] = AuditEntitiesOverviewHeaders;

    async mounted(): Promise<void> {
        await this.getEntities();
    }

    private async getEntities() {
        try {
            this.entities = await this.auditService.getEntities();
        } catch {
            this.$snackbar.danger('Er is een fout opgetreden bij het ophalen van de entiteiten');
        }
    }

    public getEntityName(entityClass: string) {
        return entityClass.split('\\').pop();
    }

    public async viewAuditLogs(entityClass: string): Promise<void> {
        await this.$router.push({
            name: 'audit-logs-overview',
            params: {
                entity: entityClass
            }
        });
    }
}
