import { AdminUsersOverviewComponent } from './overview/admin-users-overview.component';

export const AdminUserRoutingModule = [
    {
        path: 'gebruikers',
        name: 'admin-user-overview',
        component: AdminUsersOverviewComponent,
        meta: {
            requiresAuth: true,
            title: 'Gebruikers beheren',
            breadcrumb: {
                title: 'Gebruikers beheren',
            },
        },
    },
];
