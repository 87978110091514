import { RouteConfigSingleView } from 'vue-router/types/router';
import { AuditRouterViewComponent } from './pages/router-view/audit-router-view.component';
import { AuditEntitiesOverviewComponent } from './pages/audit-entities/audit-entities-overview.component';
import { AuditLogsOverviewComponent } from './pages/audit-logs/audit-logs-overview.component';

export const AuditRoutingModule: RouteConfigSingleView[] = [
    {
        path: 'audits',
        name: 'audits-overview-container',
        component: AuditRouterViewComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Audits',
                to: 'audit-entities-overview'
            }
        },
        children: [
            {
                path: 'overzicht',
                name: 'audit-entities-overview',
                component: AuditEntitiesOverviewComponent,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'logs/:entity',
                name: 'audit-logs-overview',
                component: AuditLogsOverviewComponent,
                props: true,
                meta: {
                    requiresAuth: true,
                    breadcrumb: {
                        title: 'Logs',
                    },
                },
            },
        ],
    },
];
