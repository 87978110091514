import { BaseDialog, IsDialogMixin, Observable } from '@bakerware/vue-dialogs';
import Component from 'vue-class-component';
import { Inject, Ref } from "vue-property-decorator";
import { required } from '@bakerware/vue-validation-rules';
import { TaxOfficeApiService } from "@ncnp-mono/utils";
import html from './create-api-token.dialog.html';
import { TaxOfficeApi, TaxOfficeApiRequest, TaxOfficeApiSettings, TaxOfficeApiTypeEnum } from "@ncnp-mono/generics";
import type { VForm } from "@bakerware/vue-utils";

@Component({
    template: html,
    components: {
        BaseDialog,
    },
})
export class CreateApiTokenDialog extends IsDialogMixin {

    @Inject()
    private taxOfficeApiService!: TaxOfficeApiService;

    @Ref("form")
    form!: VForm;

    public result = new Observable<TaxOfficeApi>();
    public request: TaxOfficeApiRequest = {};

    public rules = {
        type: [ required, ],
        url: [ required, ],
    };

    public loading = {
        form: false,
    };

    public created(): void {
        this.request = {};
    }

    public get taxOfficeApiTypes(): string[] {
        return Object.values(TaxOfficeApiTypeEnum);
    }

    public addSettings(type: string): void {
        try {
            this.taxOfficeApiService.getDefaultTaxOfficeApiSettings(type).then((settings: TaxOfficeApiSettings[]) =>
                this.request = {
                    ...this.request,
                    settings: [ ...settings, ],
                }
            );
        } catch (e) {
            this.$snackbar.danger('Ophalen belastingkantoor api settings mislukt');
        }
    }

    public async create(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        this.loading.form = true;

        try {
            const taxOfficeApi = await this.taxOfficeApiService.createTaxOfficeApi(
                parseInt(this.$route.params.taxOfficeId),
                this.request
            );

            this.$snackbar.success('API token aangemaakt');
            this.result.emit(taxOfficeApi);
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Er is wat mis gegaan bij het aanmaken van de API token');
        }

        this.loading.form = false;
    }
}
