import { DataTableHeader } from 'vuetify';

export const accessTokensHeaders: DataTableHeader[] = [
    {
        text: 'Naam',
        value: 'name',
        align: 'start',
        sortable: false,
    },
    {
        text: 'Scopes',
        value: 'scopes',
        align: 'start',
        sortable: false,
    },
    {
        text: 'Aangemaakt op',
        value: 'createdAt',
        align: 'start',
        sortable: false,
    },
    {
        text: 'Verloopt op',
        value: 'expiresAt',
        align: 'start',
        sortable: false,
    },
    {
        text: 'Acties',
        value: 'actions',
        align: 'end',
        sortable: false,
    },
];
