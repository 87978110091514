import Component from "vue-class-component";
import html from "./tax-office-general-data.component.html";
import { getImage, TaxOfficeService } from "@ncnp-mono/utils";
import Vue from "vue";
import type { Address, Contact, TaxOffice } from "@ncnp-mono/generics";
import { Inject, Prop } from "vue-property-decorator";
import { TaxOfficeAddressCreateDialog } from "./dialogs/tax-office-address-create.dialog";
import { TaxOfficeEditDialog } from "./dialogs/tax-office-edit.dialog";
import { TaxOfficeAddressEditDialog } from "./dialogs/tax-office-address-edit.dialog";
import { TaxOfficeContactCreateDialog } from "./dialogs/tax-office-contact-create.dialog";
import { TaxOfficeContactEditDialog } from "./dialogs/tax-office-contact-edit.dialog";
import { UploadLogoDialog } from "./dialogs/upload-logo.dialog";

@Component({
    template: html,
    methods: {
        getImage
    }
})
export class TaxOfficeGeneralDataComponent extends Vue {

    @Inject()
    private taxOfficeService!: TaxOfficeService;

    @Prop()
    private _taxOffice?: TaxOffice;
    public taxOffice: TaxOffice | null = null;

    public openTaxOfficeUploadLogoDialog(): void {
        this.$vueDialogs.open(UploadLogoDialog, {
            propsData: {
                taxOfficeId: this.taxOffice?.id,
            },
            dialogSettings: {
                maxWidth: '800px',
            },
        }).result.observe((logo: string) => this.taxOffice ? this.taxOffice.logo = logo : null);
    }

    public openEditDialog(): void {
        this.$vueDialogs.open(
            TaxOfficeEditDialog,
            {
                propsData: {
                    taxOffice: this.taxOffice
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        ).result.observe(taxOffice => this.taxOffice = taxOffice);
    }

    public openAddressCreateDialog(): void {
        this.$vueDialogs.open(
            TaxOfficeAddressCreateDialog,
            {
                propsData: {
                    taxOfficeId: this.taxOffice?.id,
                    addresses: this.taxOffice?.addresses,
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        ).result.observe(address => this.taxOffice?.addresses.push(address));
    }

    public openAddressEditDialog(address: Address): void {
        const addressEditDialog = this.$vueDialogs.open(
            TaxOfficeAddressEditDialog,
            {
                propsData: {
                    address,
                    taxOfficeId: this.taxOffice?.id,
                    addresses: this.taxOffice?.addresses,
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        );

        addressEditDialog.result.observe((_address?: Address | null) =>
            _address
                ? this.updateTaxOfficeAddress(_address)
                : this.deleteTaxOfficeAddress(address)
        );
    }

    private updateTaxOfficeAddress(address: Address): void {
        const index = this.taxOffice?.addresses.findIndex(_address => _address.id === address.id);
        this.taxOffice?.addresses.splice(<number>index, 1, address);
    }

    private deleteTaxOfficeAddress(address: Address): void {
        const index = this.taxOffice?.addresses.findIndex(_address => _address.id === address.id);
        this.taxOffice?.addresses.splice(<number>index, 1);
    }

    public openContactCreateDialog(): void {
        this.$vueDialogs.open(
            TaxOfficeContactCreateDialog,
            {
                propsData: {
                    taxOfficeId: this.taxOffice?.id
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        ).result.observe(contact => this.taxOffice?.contacts.push(contact));
    }

    public openContactEditDialog(contact: Contact): void {
        const contactEditDialog = this.$vueDialogs.open(
            TaxOfficeContactEditDialog,
            {
                propsData: {
                    contact,
                },
                dialogSettings: {
                    maxWidth: '800px',
                },
            }
        );

        contactEditDialog.result.observe((_contact?: Contact | null) =>
            _contact
                ? this.updateTaxOfficeContact(_contact)
                : this.deleteTaxOfficeContact(contact)
        );
    }

    private updateTaxOfficeContact(contact: Contact): void {
        const index = this.taxOffice?.contacts.findIndex(_contact => _contact.id === contact.id);
        this.taxOffice?.contacts.splice(<number>index, 1, contact);
    }

    private deleteTaxOfficeContact(contact: Contact): void {
        const index = this.taxOffice?.contacts.findIndex(_contact => _contact.id === contact.id);
        this.taxOffice?.contacts.splice(<number>index, 1);
    }

    private async beforeMount(): Promise<void> {
        await this.getTaxOffice();
    }

    private async getTaxOffice(): Promise<void> {
        this.taxOffice = this._taxOffice ?? await this.taxOfficeService.getTaxOffice(parseInt(this.$route.params.taxOfficeId));
    }
}
