import { IsDialogMixin, Observable } from "@bakerware/vue-dialogs";
import Component from "vue-class-component";
import { required } from "@bakerware/vue-validation-rules";
import { Inject } from "vue-property-decorator";
import html from "./create-access-token.dialog.html";
import {
    CreateTaxOfficeAccessTokenRequest, RawTokenResponse,
    TaxOfficeAccessTokensService, TokenScope
} from "@ncnp-mono/utils";
import { DatePicker } from "@bakerware/vuetify-date-picker";

@Component({
    template: html,
    components: {
        DatePicker
    }
})
export class CreateAccessTokenDialog extends IsDialogMixin {

    @Inject()
    taxOfficeAccessTokensService!: TaxOfficeAccessTokensService;

    public createAccessTokenRequest: CreateTaxOfficeAccessTokenRequest = {
        taxOfficeId: +this.$route.params.taxOfficeId,
        name: "",
        expiresAt: null,
        scopes: []
    };

    public rules = {
        name: [required]
    };

    public loading = {
        copyToken: false
    };

    public scopes: TokenScope[] = [];

    public observeCreated = new Observable<RawTokenResponse>();
    public rawToken?: string | null = null;

    public mounted(): void {
        this.loadScopes().catch(() => {
            this.$snackbar.warning("Het ophalen van scopes is mislukt");
        });
    }

    public async submit(): Promise<void> {
        const rawTokenResponse = await this.taxOfficeAccessTokensService.createAccessToken(
            +this.$route.params.taxOfficeId, this.createAccessTokenRequest
        );

        if (rawTokenResponse instanceof ErrorEvent) {
            this.$snackbar.warning("Er is een onbekende fout opgetreden");
        }

        if (rawTokenResponse.token && rawTokenResponse.accessToken) {
            this.rawToken = rawTokenResponse.token;
            this.observeCreated.emit(rawTokenResponse);
        }
    }

    public copyToken(): void {
        this.loading.copyToken = true;

        navigator.clipboard.writeText(<string>this.rawToken).then(() => {
            this.$snackbar.info(`Token opgeslagen in clipboard`);
        }).catch(() => {
            this.$snackbar.warning("Kopiëren van token niet gelukt");
        }).finally(() => {
            this.loading.copyToken = false;
        });
    }

    private async loadScopes(): Promise<void> {
        this.scopes = await this.taxOfficeAccessTokensService.getAllScopes();
    }
}
