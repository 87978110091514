import { RouteConfig } from 'vue-router';
import { BaseComponent } from '../../components/base/base.component';
import { AuthRoutingModule } from "@bakerware/vue-authentication-module";
import {
    AdminUserRoutingModule,
    AuditRoutingModule,
    DashboardRoutingModule,
    TaxOfficeRoutingModule,
    UserProfileRoutingModule
} from "../../../modules";

export const routes: RouteConfig[] = [
    ...AuthRoutingModule,
    {
        path: '/',
        name: 'core',
        component: BaseComponent,
        meta: {
            requiresAuth: true,
            breadcrumb: {
                title: 'Dashboard',
                to: 'dashboard'
            },
        },
        children: [
            ...DashboardRoutingModule,
            ...TaxOfficeRoutingModule,
            ...UserProfileRoutingModule,
            ...AdminUserRoutingModule,
            ...AuditRoutingModule,
        ],
    },
];
