import Component from 'vue-class-component';
import Vue from "vue";
import html from './admin-users-overview.component.html';
import { UsersOverviewComponent } from "@ncnp-mono/components";
import { User } from "@ncnp-mono/generics";
import { Inject } from "vue-property-decorator";
import { AdminUserService } from "@ncnp-mono/utils";

@Component({
    template: html,
    components: {
        UsersOverviewComponent,
    }
})
export class AdminUsersOverviewComponent extends Vue {

    @Inject()
    adminUserService!: AdminUserService;

    public adminUsers: User[] = [];

    mounted(): void {
        this.getAdminUsers();
    }

    private async getAdminUsers(): Promise<void> {
        this.adminUsers = await this.adminUserService.getAdminUsers();
    }
}
