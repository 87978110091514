import { DataTableHeader } from 'vuetify';

export const AuditEntitiesOverviewHeaders: DataTableHeader[] = [
    {
        text: 'Entiteit',
        value: 'entity',
        sortable: true,
    },
    {
        text: 'Tabel',
        value: 'table',
        sortable: true,
    },
    {
        text: 'Aantal bewerkingen',
        value: 'logCount',
        sortable: false,
    },
    {
        text: 'Acties',
        value: 'action',
        sortable: false,
    },
];
