import Component from 'vue-class-component';
import html from "./tax-office-user-create.dialog.html";
import {Inject, Ref} from 'vue-property-decorator';
import {IsDialogMixin, Observable} from '@bakerware/vue-dialogs';
import {TaxOfficeUserService, UserService} from "@ncnp-mono/utils";
import {TaxOfficeUserRequest, User} from "@ncnp-mono/generics";
import type {VForm} from "@bakerware/vue-utils";
import {TaxOfficeUserRules} from "../../../config/tax-office-user-rules";

@Component({
    template: html
})
export class TaxOfficeUserCreateDialog extends IsDialogMixin {

    @Inject()
    taxOfficeUserService!: TaxOfficeUserService;

    @Inject()
    userService!: UserService;

    @Ref('form')
    public form!: VForm;

    public result = new Observable<User>();
    public request: TaxOfficeUserRequest = {}
    public rules = TaxOfficeUserRules;

    async submit() {
        if (!this.form.validate()) {
            return;
        }

        try {
            const taxOfficeUser =
                await this.taxOfficeUserService.createTaxOfficeUserById(
                    this.request,
                    parseInt(this.$route.params.taxOfficeId)
                );

            this.result.emit(taxOfficeUser);
            this.$snackbar.success('Aanmaken gebruiker gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Aanmaken gebruiker mislukt');
        }
    }
}
