import Vue from "vue";
import Component from "vue-class-component";
import html from "./header.component.html";
import {getTestEnvironmentText, isTestEnvironment} from "@ncnp-mono/utils";
import {User} from "@bakerware/vue-authentication-module/dist/generics/user.view";

@Component({
    template: html,
})
export class HeaderComponent extends Vue {

    public user: User | null = this.$auth.user;

    public title = 'Gemachtigde Portaal BW Admin Omgeving';

    public redirectHome(): void {
        if (this.$route.name !== 'dashboard') {
            this.$router.push({
                name: 'dashboard',
            });
        }
    }

    private logout() {
        this.$router.push({
            path: '/logout',
        });
    }

    get isTestEnvironment(): boolean {
        return isTestEnvironment();
    }

    get testEnvironmentMessage(): string {
        return `${getTestEnvironmentText()} BW-Admin`;
    }
}
