import {BakerwareAuthSettings} from "@bakerware/vue-authentication-module";
import {User, UserGroupEnum} from "@ncnp-mono/generics";
import {ApplicationSecuritySettings} from "./application-security-settings.config";

export const bakerwareAuthSettings: BakerwareAuthSettings<User> = {
    redirectTo: {name: 'core'},
    applicationTitle: 'Gemachtigde Portaal [BW-ADMIN]',
    applicationSubTitle: 'Login',
    onVerifyUser: (user: User) => {
        return ApplicationSecuritySettings.allowedRoles.includes(<UserGroupEnum.ADMIN>user.group?.role)
    }
}
