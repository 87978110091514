import Component from "vue-class-component";
import { IsDialogMixin, Observable } from "@bakerware/vue-dialogs";
import html from "./tax-office-create.dialog.html";
import { Inject, Ref } from "vue-property-decorator";
import { TaxOfficeService } from "@ncnp-mono/utils";
import { BackendType, TaxOffice, TaxOfficeCreateRequest, TaxType } from "@ncnp-mono/generics";
import { TaxOfficeRules } from "../../../config/tax-office.rules";
import type {VForm} from "@bakerware/vue-utils";

@Component({
    template: html,
    data: () => ({
        rules: TaxOfficeRules
    })
})
export class TaxOfficeCreateDialog extends IsDialogMixin {

    @Inject()
    taxOfficeService!: TaxOfficeService;

    @Ref("form")
    form!: VForm;

    public result = new Observable<TaxOffice>();
    public backendTypes: BackendType[] = [];
    public taxTypes: TaxType[] = [];
    public request: TaxOfficeCreateRequest = {
        active: true,
    };

    public async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        try {
            const taxOffice = await this.taxOfficeService.createTaxOffice(this.request);

            this.result.emit(taxOffice);

            this.$snackbar.success("Aanmaken belastingkantoor gelukt");
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger("Aanmaken belastingkantoor mislukt");
        }
    }

    public beforeMount(): void {
        this.taxOfficeService.getBackendTypes().then(backendTypes => this.backendTypes = backendTypes);
        this.taxOfficeService.getTaxTypes().then(taxTypes => this.taxTypes = taxTypes);
    }
}
