import Component from 'vue-class-component';
import { Inject, Prop, Ref } from 'vue-property-decorator';
import html from './tax-office-address-edit.dialog.html';
import { IsDialogMixin } from "@bakerware/vue-dialogs";
import { AddressService, TaxOfficeAddressService } from "@ncnp-mono/utils";
import type { Address, AddressRequest } from "@ncnp-mono/generics";
import {Observable} from "@bakerware/vue-utils";
import type {VForm} from "@bakerware/vue-utils";
import { AddressRules } from "../../../config/address-rules";
import { ConfirmDialog } from "@ncnp-mono/components";

@Component({
    template: html,
})
export class TaxOfficeAddressEditDialog extends IsDialogMixin {

    @Inject()
    addressService!: AddressService;

    @Inject()
    taxOfficeAddressService!: TaxOfficeAddressService;

    @Ref('form')
    form!: VForm;

    @Prop()
    taxOfficeId!: number;

    @Prop()
    public address!: Address;

    public result = new Observable<Address | null>();
    public request: AddressRequest = {};
    public isFormValid = false;
    public rules = AddressRules;

    remove(): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om dit adres te verwijderen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(this.removeAddress);
    }

    async submit(): Promise<void> {
        if (!this.form.validate()) {
            return;
        }

        this.request.active = true;

        try {
            const address = await this.addressService.updateAddress(
                this.address.id,
                this.request
            );

            this.result.emit(address);

            this.$snackbar.success('Wijzigen adres gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Wijzigen adres mislukt');
        }
    }

    public created(): void {
        this.request = {
            street: this.address.street,
            number: this.address.number,
            zipCode: this.address.zipCode,
            city: this.address.city,
            active: this.address.active,
            addressType: this.address.addressType,
        };
    }

    private async removeAddress(): Promise<void> {
        try {
            await this.addressService.deleteAddress(this.address.id);

            this.result.emit(null);

            this.$snackbar.success('Verwijderen adres gelukt');
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Verwijderen adres mislukt, probeer opnieuw');
        }
    }
}
