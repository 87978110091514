import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { CreateAccessTokenDialog } from './dialogs/create-access-token/create-access-token.dialog';
import { RevokeAccessTokenDialog } from './dialogs/revoke-access-token/revoke-access-token.dialog';
import { join, pluck } from "@ncnp-mono/utils";
import {
    TaxOfficeAccessTokenInterface,
    TaxOfficeAccessTokensService
} from "@ncnp-mono/utils";
import html from './access-tokens.component.html';
import { accessTokensHeaders } from "../../config/access-tokens.headers";

@Component({
    template: html,
    filters: {
        pluck,
        join,
    },
})
export class AccessTokensComponent extends Vue {
    @Inject()
    taxOfficeAccessTokensService!: TaxOfficeAccessTokensService;

    public accessTokens: TaxOfficeAccessTokenInterface[] = [];
    public loading = true;
    public headers = accessTokensHeaders;

    public async beforeMount(): Promise<void> {
        this.accessTokens = await this.taxOfficeAccessTokensService.getTaxOfficeAccessTokens(
            parseInt(this.$route.params.taxOfficeId)
        );

        this.loading = false;
    }

    public openCreateAccessTokenDialog(): void {
        const createAccessTokenDialog = this.$vueDialogs.open(
            CreateAccessTokenDialog
        );

        createAccessTokenDialog.observeCreated.observe((rawToken) => {
            this.accessTokens = [ rawToken.accessToken, ...this.accessTokens, ];
        });
    }

    public openRevokeAccessToken(accessToken: TaxOfficeAccessTokenInterface): void {
        const revokeAccessTokenDialog = this.$vueDialogs.open(
            RevokeAccessTokenDialog,
            {
                propsData: {
                    accessToken: accessToken,
                },
            }
        );

        revokeAccessTokenDialog.confirmed.observe(() => {
            this.accessTokens = this.accessTokens.filter(_accessToken => _accessToken !== accessToken);
        });
    }
}
