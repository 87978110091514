import { BaseDialog, IsDialogMixin, Observable } from '@bakerware/vue-dialogs';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import { TaxOfficeApiService } from "@ncnp-mono/utils";
import html from './update-api-token.dialog.html';
import type { TaxOfficeApi, TaxOfficeApiRequest } from "@ncnp-mono/generics";
import { required } from "@bakerware/vue-validation-rules";

@Component({
    template: html,
    components: {
        BaseDialog,
    },
})
export class UpdateApiTokenDialog extends IsDialogMixin {
    @Inject()
    private taxOfficeApiService!: TaxOfficeApiService;

    @Prop()
    public taxOfficeApi!: TaxOfficeApi;

    public result = new Observable<TaxOfficeApi>();
    public request: TaxOfficeApiRequest = {};

    public rules = {
        url: [ required, ],
    };

    public loading = {
        form: false,
    };

    public created(): void {
        this.request = this.taxOfficeApi;
    }

    public async update(): Promise<void> {
        this.loading.form = true;

        try {
            const taxOfficeApi = await this.taxOfficeApiService.updateTaxOfficeApi(this.taxOfficeApi.id, this.request);

            this.$snackbar.success('API token bijgewerkt');
            this.result.emit(taxOfficeApi);
            this.activeDialog.close();
        } catch (e) {
            this.$snackbar.danger('Er is wat mis gegaan bij het opslaan van de API token');
        }

        this.loading.form = false;
    }
}
