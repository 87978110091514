import Vue from "vue";
import html from "./tax-office-users-overview.component.html";
import Component from "vue-class-component";
import {initials, TaxOfficeUserService, UserService} from "@ncnp-mono/utils";
import {Inject} from "vue-property-decorator";
import {User} from "@ncnp-mono/generics";
import {ConfirmDialog} from "@ncnp-mono/components";
import {TaxOfficeUserCreateDialog} from "./dialogs/tax-office-user-create.dialog";
import {TaxOfficeUserEditDialog} from "./dialogs/tax-office-user-edit.dialog";

@Component({
    template: html
})
export class TaxOfficeUsersOverviewComponent extends Vue {
    @Inject()
    taxOfficeUserService!: TaxOfficeUserService;

    @Inject()
    userService!: UserService;

    query = '';

    initials = initials;

    public taxOfficeUsers: User[] = [];

    mounted(): void {
        this.getTaxOfficeUsers(+this.$route.params.taxOfficeId);
    }

    public openTaxOfficeUserCreateDialog(): void {
        const taxOfficeUserCreateDialog = this.$vueDialogs.open(TaxOfficeUserCreateDialog, {
                dialogSettings: {
                    maxWidth: '600px',
                },
            }
        );
        taxOfficeUserCreateDialog.result.observe((user) => {
            this.taxOfficeUsers.push(user);
            this.taxOfficeUsers.sort((a, b) => a.id - b.id);
        });
    }

    public openTaxOfficeUserEditDialog(taxOfficeUser: User): void {
        const taxOfficeUserEditDialog = this.$vueDialogs.open(TaxOfficeUserEditDialog, {
            propsData: {
                taxOfficeUser,
            },
            dialogSettings: {
                maxWidth: '600px',
            },
        });
        taxOfficeUserEditDialog.result.observe((_user) => {
            this.taxOfficeUsers = this.taxOfficeUsers.map(user => {
                return user.id === _user.id ? _user : user
            })
        });
    }

    getBackgroundColorClass(user: User): string {
        return user.blocked ? 'wm-user-blocked' : '';
    }

    getColorClass(user: User): string {
        return user.active ? '' : 'wm-user-inactive';
    }

    getColor(active: boolean): string {
        return active ? 'green' : 'red';
    }

    get computedUsers(): User[] {
        if (this.query.length > 0) {
            return this.taxOfficeUsers.filter((taxOfficeUser: User) => {
                return taxOfficeUser.name.toLowerCase().includes(this.query.toLowerCase());
            });
        }

        return this.taxOfficeUsers;
    }

    async getTaxOfficeUsers(taxOfficeId: number): Promise<void> {
        this.taxOfficeUsers = await this.taxOfficeUserService.getTaxOfficeUsers(taxOfficeId);
    }

    public resetPassword(taxOfficeUser: User): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om deze gebruiker een wachtwoord reset email te sturen',
                type: 'info',
                confirmButtonText: 'Versturen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.resetPasswordDialogAction(taxOfficeUser.id));
    }

    public deleteUser(user: User): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om deze gebruiker te verwijderen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.deleteUserDialogAction(user.id));
    }

    public sendActivationMail(taxOfficeUser: User): void {
        this.$vueDialogs.open(ConfirmDialog, {
            propsData: {
                message: 'U staat op het punt om deze gebruiker een activatie email te sturen',
                type: 'info',
                confirmButtonText: 'Versturen',
            },
            dialogSettings: {
                maxWidth: '728px',
            },
        }).onConfirm.observe(() => this.sendActivationMailDialogAction(taxOfficeUser.id));
    }

    private async resetPasswordDialogAction(userId: number): Promise<void> {
        try {
            await this.userService.resetPassword(userId);
            this.$snackbar.success('Wachtwoord reset email versturen gelukt');
        } catch (e) {
            this.$snackbar.danger('Wachtwoord reset email versturen mislukt, probeer opnieuw');
        }
    }

    private async deleteUserDialogAction(userId: number): Promise<void> {
        try {
            await this.userService.deleteUser(userId);

            const index = this.taxOfficeUsers.findIndex(taxOfficeUser => taxOfficeUser.id === userId);
            this.taxOfficeUsers.splice(index, 1);

            this.$snackbar.success('Verwijderen gebruiker gelukt');
        } catch (e) {
            this.$snackbar.danger('Verwijderen gebruikt mislukt, probeer opnieuw');
        }
    }

    private async sendActivationMailDialogAction(userId: number): Promise<void> {
        try {
            await this.userService.sendActivationMail(userId);
            this.$snackbar.success('Activatie email versturen gelukt');
        } catch (e) {
            this.$snackbar.danger('Activatie email versturen mislukt, probeer opnieuw');
        }
    }
}
