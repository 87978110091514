import { DocumentCreationAdminComponent } from "@bakerware/document-creation-admin";
import { HttpService } from "@bakerware/http";
import { registerInstance, serviceContainer } from '@bakerware/vue-utils';
import { CheckEmailExistsDirective, httpService, pinia, router } from '@ncnp-mono/utils';
import Vue from 'vue';
import { AppComponent } from './app/app.component';
import { snackbar, vuetify } from './app/core/plugins/vue/plugins';

import '@fontsource/roboto/latin-100.css';
import '@fontsource/roboto/latin-300.css';
import '@fontsource/roboto/latin-400.css';
import '@fontsource/roboto/latin-500.css';
import '@fontsource/roboto/latin-700.css';
import '@fontsource/roboto/latin-900.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/dist/vuetify.min.css';
import './assets/css/style.css';

import './app/core/plugins/router';
import './app/core/plugins/vue/filters';
import './app/core/plugins/vue/plugins';

registerInstance(HttpService, httpService);

const provide = serviceContainer();

window.onload = () => {
    Vue.directive('check-email-exists', CheckEmailExistsDirective);
    Vue.component('document-creation-admin-component', DocumentCreationAdminComponent);

    new Vue({
        el: '#app',
        provide,
        pinia,
        router,
        snackbar,
        vuetify,
        render: (h) => h(AppComponent),
    });
};
