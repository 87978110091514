import { DataTableHeader } from 'vuetify';

export const TaxOfficeApiOverviewHeaders: DataTableHeader[] = [
    {
        text: 'Type',
        value: 'type',
        sortable: false,
    },
    {
        text: 'API url',
        value: 'url',
        sortable: false,
    },
    {
        text: 'Acties',
        value: 'actions',
        align: 'end',
        sortable: false,
    },
];
